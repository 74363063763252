<template>
  <div
    class="
      h-full
      bg-gray-800
      items-center
      flex flex-col
      justify-center
    "
    :class="{'min-h-screen' : !preview_mode}"
  >
    <div
      class="
        py-5
        mt-12
        w-11/12
        max-w-full
        bg-white
        rounded-lg
        text-center
        sm:max-w-lg
        items-center
        border border-gray-200
      "
    >
      <div v-if="subscriber">

        <!-- Business Logo -->
        <div
          v-if="businessLogo"
          class="flex justify-center items-center"
        >
          <img
            class="h-10"
            :src="businessLogo"
          />
        </div>
        <div v-else class="flex justify-center">
          <app-text-icon
            :text="business?.name"
            icon_outline="BriefcaseIcon"
          />
        </div>

        <hr class="mt-3" />

        <!-- Test Mode -->
        <div class="flex justify-center w-full px-8 pt-4">
          <app-test-mode-badge :testMode="testMode" />
        </div>

        <!-- Subscription Details -->
        <div class="text-left p-5 space-y-2">
          <p class="text-sm">
            {{ $t("subscription.activation.subscription_details") }}
          </p>
          <div v-if="loading">
            <paragraph-shimmer
              :is-loading="true"
              :lines="1"
              class="w-28"
              :random-size="true"
            />
            <paragraph-shimmer
              :is-loading="true"
              :lines="1"
              class="w-36"
              :random-size="true"
            />
            <paragraph-shimmer
              :is-loading="true"
              :lines="1"
              class="w-24"
              :random-size="true"
            />
          </div>
          <div v-else>
            <p class="font-medium">{{ subscriber.name ?? '-'  }}</p>
            <p class="text-gray-500">{{ subscriber.email }}</p>
            <p class="text-gray-500">{{ subscriber.phone_no }}</p>
          </div>
        </div>

        <!-- Package Details -->
        <div class="text-left p-5 bg-gray-50 border-t border-b space-y-2">
          <p>{{ $t("subscription.activation.subscription_package") }}</p>
          <div class="flex flex-wrap justify-between items-center">
            <div v-if="loading">
              <paragraph-shimmer
                :is-loading="true"
                :lines="1"
                class="w-28"
                :random-size="true"
              />
            </div>
            <div v-else>
              <p class="font-medium mr-3">{{ subscriber.package?.name ?? '-' }}</p>
            </div>
            <div v-if="loading">
              <paragraph-shimmer
                :is-loading="true"
                :lines="1"
                class="w-28"
                :random-size="true"
              />
            </div>
            <div v-else>
              <p class="text-gray-400 text-sm">
                {{
                  $formats.currency(
                    subscriber.package?.currency ?? 'MYR',
                    subscriber.package?.amount ?? 0
                  )
                }}/{{ subscriber.package?.frequency ?? '-'  }}
              </p>
            </div>
          </div>
        </div>

        <!-- Amount To Pay -->
        <div class="text-left p-5 space-y-2">
          <p>{{ $t("subscription.activation.subscription_amount") }}</p>
          <div v-if="loading">
            <paragraph-shimmer
              :is-loading="true"
              :lines="1"
              class="w-28"
              :random-size="true"
            />
          </div>
          <div v-else>
            <p class="text-2xl font-medium">
              {{
                $formats.currency(
                  subscriber.package?.currency ?? 'MYR',
                  subscriber.package?.amount ?? 0
                )
              }}
            </p>
          </div>
        </div>

        <!-- Subscription button -->
        <div class="px-5 pt-3">
          <app-button
            type="submit"
            class="w-full"
            @click="proceedSubscribe"
            :disabled="!subscriber._id"
          >
            {{ $t("subscription.activation.subscription_button") }}
          </app-button>
        </div>
      </div>
      <div v-else>{{ $t("general.no_results") }}</div>
    </div>
    <div class="py-8">
      <app-logo-power-by />
    </div>
  </div>
</template>

<script>
import { ParagraphShimmer } from "vue3-shimmer";

export default {
  components: {
    ParagraphShimmer,
  },
  data() {
    return {
      testMode: false,
      business: null,
    };
  },
  computed: {
    subscriber() {
      return this.$store.getters["subscriptionStore/subscriber"];
    },
    loading() {
      return this.$store.getters["subscriptionStore/loading"];
    },
    businessLogo() {
      return this.business?.media?.find((media) => media.collection_name == "logo")
        ?.original_url;
    },
  },
  props:{
    preview_mode: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    if (this.$route.params.subscriber_id == null) {
      this.testMode = this.$store.getters["businessStore/test_mode"] ?? false;
      await this.getDataForPreview();
      return;
    }

    if (this.$route.query.test != null && this.$route.query.test == "true") {
      this.testMode = true;
    }

    this.$store.commit("businessStore/setTestModePublicPage", this.testMode);

    await this.$store.dispatch(
      "subscriptionStore/getSubscriberPublic",
      this.$route.params.subscriber_id
    );

    this.business = this.subscriber?.package?.business;
  },

  methods: {
  
    async proceedSubscribe() {
      if (this.subscriber.proceed_activation_url == null) {
        return;
      }

      window.location.href = this.subscriber.proceed_activation_url;
    },

    async getDataForPreview() {
      let  result = await this.$store.dispatch(
        "businessStore/getBusinessFromSelectedRoleBusinessId"
      );

      this.business = result.data;
      
    },
  },
};
</script>